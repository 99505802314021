.email-verification-heading {
  font-size: var(--main-txt-size-20);
  font-weight: var(--main-font-weight-700);
  color: var(--main-txt-dull-navy-color);
  line-height: var(--main-line-height-20);
  text-align: center;
}

.common-login-field.email-verifications {
  justify-content: center;
  height: 92%;
}

.email-description {
  font-size: var(--main-txt-size-14);
  line-height: var(--main-line-height-24);
  color: var(--main-txt-dark-shade-gray-color);
  margin: 20px 0px;
  text-align: center;
}

.email-logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 37px 0px 86px;
}

.resend-email-button {
  margin: 40px 0px;
}