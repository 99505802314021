.support-container {
  display: flex;
  height: calc(100vh - 60px);
}

.right-side-nav-container {
  flex-basis: 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contact-us {
  margin: 16px 25px 0px;
}

.side-nav-container.summary-desc {
  justify-content: initial;
}

.documentations-wrapper {
  flex-basis: 80%;
  border-right: 1px solid #f2f2f2;
}

.popular-topics {
  padding-top: 20px;
  padding-left: 45px;
  overflow-y: auto;
  height: calc(100vh - 172px);
  padding-left: 45px;
}

.support-list {
  flex-basis: 20%;
}

.summary {
  color: var(--main-txt-dark-charcoal-color);
  font-size: var(--main-txt-size-24);
  margin-bottom: 24px !important;
}

.contact-btn {
  padding-bottom: 20px;
}

.btn-contact {
  cursor: pointer;
  text-align: center;
  border: 1px solid #45924b;
  color: #45924b;
  background: white;
  border-radius: 8px;
  width: 100%;
  font-weight: var(--main-font-weight-500);
  font-size: var(--main-txt-size-16);
  height: 44px;
}

.btn-contact:hover {
  background-color: #0db02b;
  color: var(--main-txt-white-color);
}

.footer-contact {
  padding: 36px 25px;
  border-top: 1px solid #f2f2f2;
}

.footer-contact li {
  padding-bottom: 10px;
}

.icon-link-width {
  width: 100%;
  display: inline-block;
  vertical-align: bottom;
}

.label-width {
  margin-left: 10px;
  color: var(--main-border-grey-color);
  font-size: var(--main-txt-size-14);
  line-height: var(--main-line-height-16);
  font-weight: var(--main-font-weight-500);
}