.login-button {
  margin: 16px 0px 20px;
}

.btn-login {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--main-bg-green-color);
  height: 44px;
  color: var(--main-txt-white-color);
  border: none;
  border-radius: 5px;
  width: 100%;
  font-weight: var(--main-font-weight-600);
  font-size: var(--main-txt-size-16);
  line-height: var(--main-line-height-20);
}

.btn-login label {
  cursor: pointer;
}

.login-input-feild {
  position: relative;
  display: block;
  width: 100%;
}

.error-text {
  display: block;
  font-weight: var(--main-font-weight-600);
  font-size: var(--main-txt-size-10);
  line-height: var(--main-line-height-12);
  color: #eb5757;
  margin-top: 5px;
  height: 12px;
}

.error-text.visible {
  visibility: visible;
}

.error-text.hidden {
  visibility: hidden;
}

.forgot-password-text-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.forgot-password-text {
  font-weight: var(--main-font-weight-600);
  font-size: var(--main-txt-size-13);
  line-height: var(--main-line-height-18);
  color: var(--main-txt-dull-navy-color) !important;
}

.google-login {
  margin: 20px 0px;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.google-login button {
  font-size: var(--main-txt-size-16) !important;
  line-height: 19px !important;
  color: #000000 !important;
  width: 100%;
  border-radius: 8px !important;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}

.common-login-field-label {
  color: var(--main-txt--sat-lite-sure-grey);
  font-size: var(--main-txt-size-16);
  font-weight: var(--main-font-weight-600);
}

.login-content {
  flex-basis: 60%;
}

.select_red.react-select__control {
  border: 1px solid red !important;
}

.google-login-wrapper label {
  margin-left: 10px;
  font-size: var(--main-txt-size-16);
  line-height: var(--main-line-height-19);
  cursor: pointer;
}

.signup-link {
  text-align: center;
  font-size: var(--main-txt-size-16);
  line-height: var(--main-line-height-19);
  margin-top: 24px;
  font-weight: var(--main-font-weight-500);
}

.signup-link label {
  color: var(--main-txt-grey-color);
  padding-right: 10px;

}

.signup-link a {
  color: #42444A !important;
  cursor: pointer;
  font-weight: var(--main-font-weight-700);
}

.gamil-valid {
  display: flex;
  justify-content: center;
  align-items: center;
}

.final-sections .signup-link {
  margin: 30px 0px;
}

.final-continue h1 {
  margin-bottom: 30px;
}

.gamil-valid span {
  font-weight: var(--main-font-weight-500);
  font-size: var(--main-txt-size-16);
  line-height: var(--main-line-height-19);
  color: var(--main-txt-grey-color);
  margin-left: 15px;
}

.continue-mail {
  margin: 60px 0px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.continue-mail label {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.logged-in {
  border: 1px solid #e0e0e0;
  padding: 22px;
}

.continue-mail label img {
  width: 100%;
  height: 100%;
}

.continue-mail span {
  font-weight: var(--main-font-weight-500);
  font-size: var(--main-txt-size-16);
  line-height: var(--main-line-height-19);
  color: var(--main-txt-grey-color);
}

.edit-email {
  position: absolute;
  right: 0px;
  bottom: 10px;
}

.common-login-field {
  width: 363px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

#container.haAclf {
  padding: 0px 0px !important;
  width: 100% !important;
}