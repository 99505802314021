.password-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.password-wrapper {
  flex-basis: 60%;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 60px;
}

.password-desc-wrapper {
  flex-basis: 40%;
  padding: 20px;
}

.password-group {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
}

.password-group label {
  font-weight: var(--main-font-weight-600);
  font-size: var(--main-txt-size-14);
  line-height: 28px;
  color: #606060;
}

.old-password-wrapper {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  position: relative;
  width: 50%;
}

.new-password-wrapper {
  position: relative;
  width: 50%;
}

.old-password-wrapper .password-input,
.new-password-wrapper .password-input {
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  height: 36px;
  width: 100%;
}

.old-password-wrapper .password-input:focus,
.new-password-wrapper .password-input:focus {
  outline: none;
}

.old-password-wrapper span {
  color: var(--main-txt-dull-navy-color);
  font-weight: var(--main-font-weight-500);
  font-size: var(--main-txt-size-14);
  line-height: var(--main-line-height-18);
  margin-top: 8px;
}

.btn-update {
  width: 112px;
  height: 36px;
  border-radius: 5px;
  background: var(--main-bg-green-color);
  color: var(--main-txt-white-color);
  font-size: var(--main-txt-size-16);
  font-weight: var(--main-font-weight-500);
  line-height: var(--main-line-height-19);
}

.btn-update:disabled {
  background-color: #e0e0e0;
}

.btn-update-group {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.password-desc-heading {
  font-size: var(--main-txt-size-20);
  color: var(--main-txt-dark-charcoal-color);
}

.passowrd-order-list {
  padding: 20px;
}

.passowrd-order-list li {
  list-style-type: disc;
}

.passowrd-order-list li:not(:last-child) {
  margin-bottom: 20px;
}

.password-input.red {
  border: 1px solid red;
  height: 36px;
  border-radius: 4px;
}

.password-input:focus {
  outline: none;
}
.password-icon {
  cursor: pointer;
  position: absolute;
  inset: 8px 10px auto auto;
  width: 20px;
  height: 20px;
}
