.map-control {
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.map-control img {
  width: 100%;
  height: 100%;
}

.map-controls-section {
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  right: 8px;
  z-index: 2 !important;
}

.map-controls-section li:not(:last-child) {
  margin-bottom: 5px;
}

.opacity-popup.hidden {
  opacity: 0;
  transition-duration: 1s;
}

.map-list {
  position: relative;
}

.refocus:hover .control-hover,
.full-screen:hover .control-hover,
.opacity-sections:hover .control-hover,
.zoom-out:hover .control-hover,
.zoom-in:hover .control-hover {
  display: flex;
  visibility: visible;
  transition-duration: 0.6s;
  transition-delay: 0.6s;
}

.control-hover {
  /* display: none; */
  visibility: hidden;
  position: absolute;
  right: 38px;
  top: 2px;
  padding: 4px 16px;
  border-radius: 4px;
  background: rgba(3, 3, 3, 0.75);
  width: 116px;
  align-items: center;
  justify-content: center;
  height: 28px;
}

.control-hover span {
  position: absolute;
  right: 0px;
  top: 7px;
}

.control-hover span::before {
  clip-path: polygon(0 0, 0% 100%, 100% 50%);
  background: rgba(3, 3, 3, 0.75);
  content: '';
  height: 15px;
  position: absolute;
  width: 12px;
}

.control-hover label {
  display: block;
  color: #FFF;
  font-size: 12px;
  font-weight: 500;
}

.opacity-popup {
  opacity: 1;
  transition-duration: 1s;
  position: absolute;
  right: 29px;
  top: 30px;
  width: 220px;
  background: #FFFFFF;
  box-shadow: -4px -4px 36px rgba(90, 90, 90, 0.25);
  border-radius: 8px;
  padding: 15px;
}

.opacity-popup-layer {
  left: auto !important;
  right: 30px !important;
}

.opacity-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.opacity-header-title {
  font-weight: var(--main-font-weight-500);
  font-size: var(--main-txt-size-12);
  line-height: var(--main-line-height-14);
  text-overflow: ellipsis;
  overflow: hidden;
}

.opacity-header .opacity-header-title {
  color: var(--main-txt-dark-charcoal-color);
}

.opacity-crop-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
}

.opacity-crop-name .opacity-header-title {
  color: #261E80;
}

.opacity-crop-name span {
  width: 20px;
}

.opacity-crop-name span img {
  width: 100%;
  height: 100%;
}

.opacity-header span {
  cursor: pointer;
  display: flex;
  width: 12px;
  height: 12px;
}

.opacity-header span img {
  width: 100%;
  height: 100%;
}

.opacity-list {
  margin-top: 10px;
  padding-left: 0px;
}

.opacity-list:not(:last-child) {
  margin-bottom: 16px;
}

.opacity-list li label {
  background: var(--main-bg-green-color);
  border-radius: 2px;
  padding: 6px;
  margin-right: 8px;
}

.opacity-list li span {
  font-weight: var(--main-font-weight-500);
  font-size: var(--main-txt-size-12);
  line-height: var(--main-line-height-14);
  color: var(--main-txt-dull-navy-color);
}

.opacity-sections {
  position: relative;
}

.ol-zoom {
  display: none !important;
}

.ol-full-screen-false {
  display: none !important;
}

/* .rc-slider-handle */
.rc-slider-handle {
  width: 10px;
  height: 10px;
  margin-top: -3px;
  border-color: #261E80 !important;
  box-shadow: 0 0 0 5px #261E80 !important;
  border: solid 2px px #261E80;
  pointer-events: stroke;
}