.url-section {
  padding: 10px 0px;
  display: block;
}

.btn-tryout {
  height: 36px;
  font-size: var(--main-txt-size-14);
  width: 120px;
  border: 1px solid var(--main-bg-green-color);
  border-radius: 8px;
  font-weight: var(--main-font-weight-600);
  background: white;
}

.url-copy-section {
  background: #f2f2f2;
  display: flex;
  align-items: center;
  height: 50px;
  border-radius: 8px;
  position: relative;
}

.url-copy-section p {
  margin-left: 20px;
}

.copy-icon {
  position: absolute;
  inset: 15px 25px auto auto;
  width: 20px;
  height: 20px;
}

.desc-section {
  padding: 15px 0px;
}

.horizontal-line {
  margin: 10px 0px 20px 0px;
}

.response-section {
  background: #f2f2f2;
  height: 225px;
  border-radius: 8px;
  margin-top: 10px;
  width: 100%;
  font-size: var(--main-txt-size-14);
  overflow-y: auto;
  position: relative;
  padding: 20px;
}

.sub-header span {
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
  vertical-align: middle;
}

.sub-header label {
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
  margin-left: 20px;
}