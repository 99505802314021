.setting-container {
  display: flex;
  height: calc(100vh - 60px);
}

.setting-sections {
  flex-basis: 20%;
  box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.12);
}

.setting-list {
  flex-basis: 80%;
}