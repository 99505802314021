.request-header {
  display: flex;
  padding: 25px 20px;
  align-items: center;
  justify-content: space-between;
}

.search-header {
  display: flex;
  align-items: center;
}

.request-header .image-wrapper {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.request-heading {
  margin: 0px 20px;
  display: flex;
  font-size: var(--main-txt-size-22);
  font-weight: var(--main-font-weight-600);
}

.apicallsmade-popup {
  position: relative;
}

.info-img img {
  width: 100%;
  height: 100%;
}

.green-text {
  color: #45924b;
}

.info-img:hover~.apicallsmade-info-popup {
  display: block;
}

.apicallsmade-info-popup {
  display: none;
  position: absolute;
  z-index: 2;
  background: rgba(0, 0, 0, 0.92);
  border-radius: 8px;
  padding: 10px;
  top: 32px;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 210px;
}

.apicallsmade-info-popup::before {
  content: '';
  height: 10px;
  position: absolute;
  width: 15px;
  top: -10px;
  left: 50%;
  transform: translate(-50%, 0%);
  clip-path: polygon(50% 0, 0 100%, 100% 100%);
  background-color: #030303;
}

.apicallsmade span {
  vertical-align: text-bottom;
  display: inline-block;
  padding-left: 4px;
}

/* Search box css */
.request-search {
  width: 361px;
  position: relative;
}

.request-search img {
  position: absolute;
  inset: 10px;
}

.request-search .search-input {
  width: 100%;
  border: 1px solid #a4a4a4;
  border-radius: 5px;
  outline: none;
  height: 36px;
  padding-left: 40px;
}

.search-input::placeholder {
  font-size: var(--main-txt-size-14);
  line-height: var(--main-line-height-16);
}

.search-input:focus {
  outline: none;
}

/* TABLE CSS */

.table> :not(caption)>*>* {
  padding: 0 !important;
  border-bottom-width: 0px !important;
  box-shadow: transparent !important;
}

.table-container {
  padding: 0px 20px;
}

.table-head {
  height: 75px;
  background-color: #f3f3f3;
}

.table-head th {
  padding: 10px 20px;
  font-weight: var(--main-font-weight-600);
  font-size: var(--main-txt-size-14);
  line-height: var(--main-line-height-16);
  font-weight: var(--main-font-weight-600);
  color: #a4a4a4;
  vertical-align: middle;
}

thead,
tbody tr {
  width: 100%;
  table-layout: fixed;
  display: table;
}

.table-body {
  border: 1px solid #e0e0e0;
  border-top: none;
  border-radius: 0px 0px 8px 8px;
  display: block;
  height: calc(100vh - 290px);
  overflow: auto;
}

tbody .expandable-row {
  height: 80px;
  vertical-align: middle;
}

.table-body .expandable-row:not(.parent) {
  border-bottom: 1px solid #f2f2f2;
}

.right-arrow,
img {
  cursor: pointer;
}

.right-arrow.rotate img {
  transform: rotate(90deg);
}

tbody .expandable-row.parent td {
  border-bottom: none !important;
}

td:first-child label {
  margin-left: 10px;
}

.file-name {
  color: var(--main-txt-dark-blue-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 75%;
  display: inline-block;
  vertical-align: inherit;
}

.file-name-aoi-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 75%;
  display: inline-block;
  vertical-align: inherit;
}

.file-name::first-letter {
  text-transform: uppercase;
}

.filename-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.child-row td:first-child label {
  margin-left: 18px;
}

tbody .child-row {
  height: 60px;
  vertical-align: middle;
}

.action-approve {
  background: #c2ebca4d;
  padding: 8px 16px;
  border-radius: 5px;
  color: #45924b;
  font-size: var(--main-txt-size-12);
  line-height: var(--main-line-height-24);
  margin-right: 5px;
  font-weight: var(--main-font-weight-600);
}

.status {
  position: relative;
}

.status label:hover~.status-text {
  visibility: visible;
}

.status-text {
  left: 50%;
  transform: translate(-50%, 0%);
  position: absolute;
  visibility: hidden;
  padding: 8px 16px;
  border-radius: 5px;
  margin-right: 5px;
  bottom: -20px;
  z-index: 9;
}

.status-text p {
  font-weight: var(--main-font-weight-500);
  font-size: var(--main-txt-size-12);
  line-height: var(--main-line-height-16);
}

.status-text::before {
  content: '';
  height: 10px;
  position: absolute;
  width: 15px;
  top: -10px;
  left: 50%;
  transform: translate(-50%, 0%);
  clip-path: polygon(50% 0, 0 100%, 100% 100%);
  background: var(--main-bg-green-color);
}

.status-text.Requested {
  color: var(--main-txt-dark-blue-color);
  background: #d5e6ff;
}

.status-text.Requested::before {
  background: #d5e6ff;
}

.status-text.InProgress {
  background: #a4a4a4;
  color: var(--main-txt-approx-steel-grey-color);
}

.status-text.InProgress::before {
  background: #a4a4a4;
}

.status-text.Completed {
  color: #0db02b;
  background: #eff9f0;
}

.status-text.Completed::before {
  background: #eff9f0;
}

.status-text.Declined {
  background: rgba(235, 87, 87, 0.15);
  color: #eb5757;
}

.status-text.Declined::before {
  background: rgba(235, 87, 87, 0.15);
}

.action-reject {
  background: #eb575726;
  border-radius: 5px;
  color: #eb5757;
  font-size: var(--main-txt-size-12);
  line-height: var(--main-line-height-24);
  padding: 8px 16px;
  font-weight: var(--main-font-weight-600);
  position: relative;
}

.action-reject img {
  margin-left: 3px;
  width: 8px;
  height: 8px;
}

/* Drop downs */
.dropdown-toggle::after {
  display: none;
}

/* Action menu drop down */
.action {
  font-size: var(--main-txt-size-14);
  line-height: var(--main-line-height-24);
  padding: 5px 8px;
  font-weight: var(--main-font-weight-500);
  cursor: pointer;
  position: relative;
  width: 90px;
  display: inline-block;
}

.action img {
  margin-left: 20px;
}

.menu-item.approve {
  background: #c2ebca4d;
  border-radius: 5px;
  height: 27px;
  margin-bottom: 5px;
}

.menu-item.approve-disable {
  background: #c7c7cc;
  border-radius: 5px;
  height: 27px;
  margin-bottom: 5px;
  cursor: not-allowed;
}

.menu-item .approve {
  color: #45924b;
  border-radius: 3px;
  margin-left: 0px;
  cursor: pointer;
}

.menu-item .approve-disable {
  color: #696b72;
  border-radius: 3px;
  margin-left: 0px;
  cursor: not-allowed;
}

.menu-item.reject {
  background: #eb575726;
  border-radius: 5px;
  height: 27px;
}

.menu-item .reject {
  color: #eb5757;
  border-radius: 3px;
  cursor: pointer;
}

.rejected-person {
  color: #696b72;
  font-size: var(--main-txt-size-10);
  font-weight: var(--main-font-weight-500);
  display: block;
  margin-top: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.menu-item span {
  display: inline-block;
  width: 15px;
}

.menu-item span img {
  width: 100%;
  height: 100%;
}

.action.dropdown-toggle.show {
  border-radius: 5px;
  border: 1px solid lightgray;
}

.action.dropdown-toggle.show img {
  transform: rotate(180deg);
}

.menu-item label {
  font-size: var(--main-txt-size-12);
  font-weight: var(--main-font-weight-600);
  color: var(--main-txt--sat-sure-grey-grey-3);
  line-height: var(--main-line-height-16);
  margin-left: 5px;
  cursor: pointer;
}

.dropdown-menu.option-menu {
  min-width: 90px;
  padding: 8px;
  text-align: center;
  position: absolute;
  inset: 5px 0px auto auto !important;
  margin: 0px;
}

.dropdown-menu.docs-menu {
  min-width: 90px;
  padding: 5px 10px;
  position: absolute;
  inset: 5px 5px auto auto !important;
  margin: 0px;
}

/* Table css */
.daterange {
  width: 9%;
}

.docs {
  position: relative;
}

.status,
.docs {
  width: 6%;
}

.table-body .child-row:last-child {
  border-bottom: 1px solid #f2f2f2;
}

/* Filter popup */
.filter-column {
  position: relative;
}

.column-wrapper {
  border: 1px solid #a4a4a4;
  height: 35px;
  width: 100px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-weight: var(--main-font-weight-500);
  font-size: var(--main-txt-size-14);
  line-height: var(--main-line-height-20);
  color: #696b72;
  cursor: pointer;
}

.filter-popup {
  background: #ffffff;
  width: 300px;
  height: 230px;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  z-index: 1;
  display: flex;
  position: absolute;
  inset: 40px 0px auto auto;
  flex-direction: column;
  position: absolute;
  justify-content: space-between;
  padding: 20px;
  visibility: collapse;
}

.filter-popup.show {
  visibility: visible;
}

.filter-wrapper {
  display: flex;
  justify-content: space-between;
}

.filter-popup ul {
  display: flex;
  flex-direction: column;
  padding: 0px;
}

.filter-popup ul li {
  height: 25px;
}

.filter-popup ul li label {
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-600);
  margin-left: 10px;
  line-height: var(--main-line-height-20);
}

.button-group {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.button-cancel {
  background-color: #ffff;
  outline: none;
}

.button-apply {
  margin-left: 20px;
  width: 65px;
  height: 28px;
  border-radius: 5px;
  background: #0db02b;
  color: #ffff;
  font-size: var(--main-txt-size-12);
  font-weight: var(--main-font-weight-600);
}

.no_data_row {
  border-bottom: 1px solid #e0e0e0cd !important;
}

.sortable-header {
  cursor: pointer;
}

.request-table-calendar .react-datepicker-wrapper {
  width: 115px;
}

.request-calendar {
  text-align: center;
  border: none !important;
  font-size: var(--main-txt-size-15) !important;
  line-height: var(--main-line-height-20) !important;
  color: var(--main-txt-dark-charcoal-color);
  font-weight: var(--main-font-weight-500) !important;
  border: none;
  outline: none;
  caret-color: transparent;
  cursor: pointer;
  padding: 0px;
}

.request-calendar:focus {
  border: none;
  outline: none;
}

/* rejection popup modal */

.rejection-header {
  padding: 13px 12px 13px 23px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
}

.rejection-header h1 {
  font-size: var(--main-txt-size-16);
  font-weight: var(--main-font-weight-600);
  line-height: var(--main-line-height-20);
}

.rejection-content {
  padding: 16px 24px 20px 24px;
}

.radio-group {
  display: flex;
  padding: 5px 0px;
}

.radio-group label {
  font-size: var(--main-txt-size-12);
  font-weight: var(--main-font-weight-600);
  line-height: var(--main-line-height-20);
  margin-left: 8px;
  color: var(--main-txt-approx-steel-grey-color);
}

.radio-group input {
  accent-color: var(--main-txt-approx-steel-grey-color);
  cursor: pointer;
}

.radio-group input:placeholder-shown {
  font-size: var(--main-txt-size-14);
  color: var(--main-txt--sat-sure-grey-grey-3);
  font-weight: var(--main-font-weight-500);
}

.text-area {
  margin: 16px 0px;
}

.reason-text {
  padding: 8px 16px;
  width: 100%;
  border: 1px solid#95969D;
  border-radius: 8px;
  padding-left: 10px;
  resize: none;
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
}

.reason-text.selected {
  border: 1px solid #030303;
}

.reason-text.selected:focus {
  border: 1px solid #030303;
}

.reason-text:focus {
  border: 1px solid #95969d;
  outline: 0;
}

.button-cancel.popup {
  border: 1px solid #0db02b;
  margin-left: 15px;
  border-radius: 8px;
  font-size: var(--main-txt-size-14);
  line-height: var(--main-line-height-24);
  font-weight: var(--main-font-weight-500);
  color: #0db02b;
}

.down-arrow-image {
  transform: rotate(90deg);
  margin-left: 8px;
}

.down-arrow-image.rotate {
  transform: rotate(270deg);
}

.api-key .key-select__control {
  margin-right: 0px;
  width: auto;
}

.table {
  margin-bottom: 0px;
}

.pagination {
  display: flex !important;
  justify-content: flex-end;
  border-bottom: none !important;
}

.empty-row {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
}

.updated-by-info {
  color: #696b72;
  font-size: var(--main-txt-size-10);
  font-weight: var(--main-font-weight-500);
  padding-top: 8px;
  padding-left: 46px;
  text-align: initial;
}