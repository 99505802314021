.reset-password-container {
  display: flex;
  width: 100%;
  height: 100vh;
}

.reset-password-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 10%;
}

.reset-success {
  margin-top: 73px
}

.reset-password-header h1 {
  font-weight: var(--main-font-weight-700);
  font-size: var(--main-txt-size-20);
  line-height: var(--main-txt-size-24);
  color: var(--main-txt-grey-color);
}

.reset-password-header span {
  font-weight: var(--main-font-weight-600);
  font-size: var(--main-txt-size-14);
  line-height: var(--main-txt-size-18);
  color: var(--main-border-grey-color);
  margin-top: 4px;
}

.input-field-title {
  font-weight: var(--main-font-weight-800);
  font-size: var(--main-txt-size-16);
  line-height: var(--main-txt-size-24);
  color: var(--main-txt--sat-sure-grey-grey-3);
}

.reset-password-button-container {
  margin-top: 40px;
}

.reset-password-sections {
  flex-basis: 60%;
}

.reset-password-button-container button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: var(--main-bg-green-color);
  height: 36px;
  color: var(--main-txt-white-color);
  border: none;
  border-radius: 5px;
  width: 100%;
  font-weight: var(--main-font-weight-600);
  font-size: var(--main-txt-size-16);
  line-height: var(--main-txt-size-20);
}

.reset-password-button-container label {
  cursor: pointer;
}

.reset-password-success-header h1 {
  font-size: var(--main-txt-size-20);
  font-weight: var(--main-font-weight-700);
  color: var(--main-txt-dull-navy-color);
  line-height: var(--main-line-height-24);
  text-align: left;
}

.reset-password-success-header p {
  font-size: var(--main-txt-size-14);
  line-height: var(--main-line-height-24);
  padding-bottom: 44px;
  text-align: left;
  color: #219653;
  margin-top: 10px;
}

.reset-password-success-logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.reset-password-success-login-btn {
  margin: 44px 0;
}