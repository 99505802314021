.req-comp-sections {
  flex-basis: 20%;
  box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.12);
  height: calc(100vh - 177px);
  overflow-y: auto;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f2f2f2;
  padding: 10px 10px 10px 24px;
}

.title {
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-600);
  line-height: var(--main-line-height-16);
  color: var(--main-txt-black-color);
}

.tree-container {
  overflow-y: scroll;
  margin: 20px 0px 20px 24px;
  padding-right: 24px;
  max-height: calc(100vh - 380px);
}

.notes {
  margin-top: 42px;
  padding: 0px 20px;
}

.compare-button {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--main-txt-white-color);
  padding: 7px 10px;
  border: 1px solid var(--main-border-green-color);
  width: 45%;
  font-size: var(--main-txt-size-16);
  border-radius: 6px;
  color: var(--main-txt-green-color);
  transition: all 0.3s ease-in-out;
  font-size: var(--main-txt-size-12);
  font-weight: var(--main-font-weight-500);
  font-family: var(--main-font-family);
}

.compare-button-selected {
  background-color: var(--main-bg-green-color);
  color: var(--main-txt-white-color);
}

.compare-button:hover {
  cursor: pointer;
}

.compare-button-cross {
  margin-left: 4px;
  height: 16px;
  width: 16px;
  color: var(--main-text-green-color);
}

.req-comp-sections .sub-request {
  display: flex;
  align-items: center;
  padding-top: 7px;
  margin-left: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.req-comp-sections .icon {
  width: 16px;
  height: 16px;
  margin-right: 12px;
}

.sub-request-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  display: inline-block;
  color: var(--main-txt-grey-color);
}

.sub-request-name-disable {
  color: var(--main-txt-lite-grey-color);
}

.note {
  font-size: var(--main-txt-size-14);
  color: var(--main-txt-grey-color);
  font-weight: var(--main-font-weight-500);
}

.treeview {
  position: relative;
}

.req-title {
  display: none;
}

.tree-title:hover~.req-title {
  position: absolute;
  z-index: 1;
  background: rgba(0, 0, 0, 0.92);
  border-radius: 8px;
  padding: 5px;
  display: block;
}

.view-all {
  font-weight: var(--main-font-weight-600);
  font-size: var(--main-txt-size-12);
  line-height: var(--main-line-height-14);
  color: var(--main-txt-dark-blue-color);
  float: right;
  margin-right: 14px;
  cursor: pointer;
}

.req-title-content {
  font-size: var(--main-txt-size-12);
  line-height: var(--main-line-height-14);
  color: var(--main-txt-white-color);
}