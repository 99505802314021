.forgot-password-container {
  display: flex;
  width: 100%;
  height: 100vh;
}

.forgot-password-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 10%;
}

.forgot-password-sections {
  flex-basis: 60%;
}

.forgot-password-header h1 {
  font-weight: var(--main-font-weight-700);
  font-size: var(--main-txt-size-20);
  line-height: var(--main-txt-size-24);
  color: var(--main-txt-grey-color);
  margin-bottom: 24px;
}

.forgot-password-header span {
  font-weight: var(--main-font-weight-600);
  font-size: var(--main-txt-size-14);
  line-height: var(--main-txt-size-18);
  color: var(--main-border-grey-color);
  margin-top: 4px;
}

.input-field-title {
  font-weight: var(--main-font-weight-800);
  font-size: var(--main-txt-size-16);
  line-height: var(--main-txt-size-24);
  color: var(--main-txt--sat-sure-grey-grey-3);
}

.input-field {
  position: relative;
  display: block;
  width: 100%;
  margin-top: 8px;
}

.success-status-text {
  font-weight: var(--main-font-weight-600);
  font-size: var(--main-txt-size-14);
  margin-top: 5px;
  line-height: var(--main-txt-size-18);
  color: #219653;
}

.error-status-text {
  display: block;
  font-weight: var(--main-font-weight-600);
  font-size: var(--main-txt-size-14);
  margin-top: 5px;
  height: 12px;
  line-height: var(--main-txt-size-18);
  color: #eb5757;
}

.forgot-password-button-container {
  margin: 90px 0 16px 0;
}

.forgot-password-button-container button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: var(--main-bg-green-color);
  height: 36px;
  color: var(--main-txt-white-color);
  border: none;
  border-radius: 5px;
  width: 100%;
  font-weight: var(--main-font-weight-600);
  font-size: var(--main-txt-size-16);
  line-height: var(--main-txt-size-20);
}

.forgot-password-button-container label {
  cursor: pointer;
}

.back-to-login-link {
  text-align: center;
  font-size: var(--main-txt-size-14);
  line-height: var(--main-txt-size-18);
  margin-top: 20px;
  font-weight: var(--main-font-weight-500);
}

.back-to-login-link label {
  color: var(--main-txt-grey-color);
}

.back-to-login-link a {
  color: #42444A !important;
  cursor: pointer;
  font-weight: var(--main-font-weight-700);
}

.support-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
}

.support-wrapper label {
  font-weight: var(--main-font-weight-500);
  font-size: var(--main-txt-size-14);
  line-height: var(--main-txt-size-16);
  text-align: center;
  color: var(--main-border-grey-color);
}

.support-wrapper a {
  font-weight: var(--main-font-weight-500);
  font-size: var(--main-txt-size-14);
  line-height: var(--main-txt-size-16);
  text-align: center;
  color: var(--main-txt-green-color) !important;
}