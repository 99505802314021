.map-container {
  width: 100%;
  height: 100%;
  position: relative;
  flex-basis: 87%;
}

.map-layout-container {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 8px;
  background-color: #f3f3f3;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.legend-title-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
  flex-basis: 5%;
  background-color: #fff;
}

.legend-title-header.flexEnd {
  justify-content: flex-end;
}

.legend-title-header h2 {
  font-size: var(--main-txt-size-10);
  font-weight: 600;
  line-height: 14px;
  color: #42444a;
}

.croparea-aoi {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
}

.product-colour-img {
  width: 100%;
  height: 35px;
}

.product-colour-img img {
  width: 100%;
  height: 100%;
  display: flex;
  padding-bottom: 4px;
  padding: 0px 6px 4px 0px;
  border-radius: 8px;
  background-color: #f6f6f6;
}

.aoi-sqm {
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 6px 13px;
  float: right;
}

.aoi-sqm span,
.aoi-sqm label {
  font-size: var(--main-txt-size-12);
  line-height: var(--main-line-height-16);
  font-weight: var(--main-font-weight-700);
  letter-spacing: 0.07em;
  color: var(--main-txt-dark-charcoal-color);
}

.single-merge-title,
.single-merge-legend {
  display: flex;
  flex-basis: 5%;
  justify-content: space-between;
}

.single-merge-legend {
  flex-basis: 8%;
}

.single-merge-legend .map-descriptions {
  flex-basis: 45%;
}

.single-merge-title .legend-title-header {
  flex-basis: auto;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  border-radius: 50%;
  border: 5px solid rgb(252, 252, 252);
  border-top-color: rgba(0, 0, 0, 0.6);
  animation: spinner 0.6s linear infinite;
}

.ol-overlaycontainer-stopevent {
  /* display: none; */
}

.map_filename {
  bottom: 0;
}

.map-descriptions {
  padding-left: 20px;
  flex-basis: 8%;
  display: flex;
  align-items: center;
}

.map-descriptions h2 {
  font-size: var(--main-txt-size-10);
  line-height: var(--main-line-height-12);
  color: var(--main-txt-black-color);
}

.map-header {
  position: relative;
}

.close-icon img {
  width: 100%;
  height: 100%;
}

.close-icon:hover {
  border-radius: 4px;
  background: #EEE;
}

.map-notes-document {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 8px;
}

.map-notes-document p {
  font-size: var(--main-txt-size-12);
  line-height: var(--main-line-height-14);
  color: var(--main-txt-black-color);
}

.delivery-document label {
  font-size: var(--main-txt-size-12);
  line-height: var(--main-line-height-14);
  color: var(--main-txt-black-color);
  margin-right: 9px;
}

.delivery-document span {
  cursor: pointer;
}

.data-availability {
  width: 152px;
  position: absolute;
  bottom: 10px;
  right: 8px;
  z-index: 9;
}

.data-list-title {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.data-availability-list {
  margin-bottom: 3px !important;
}

.data-list-title,
.data-availability-list {
  padding: 6px 14px;
  background-color: rgba(0, 0, 0, 0.63);
  border-radius: 4px;
}

.data-list-title label {
  font-size: var(--main-txt-size-12);
  line-height: var(--main-line-height-14);
  color: var(--main-txt-lite-grey-color);
}

.availability-arrow {
  width: 10px;
  transition: 1s;
}

.availability-arrow img {
  width: 100%;
  height: 100%;
}

.rotate-arrow {
  transition: 1s;
  transform: rotate(180deg);
}

.data-availability-list li span {
  font-size: var(--main-txt-size-12);
  line-height: var(--main-line-height-14);
  color: var(--main-txt-lite-grey-color);
}

.data-availability-list li label {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-right: 10px;
}

.data-availability-list li:first-child label {
  background-color: #0fc831;
}

.data-availability-list li:nth-child(2) label {
  background-color: #a7a70b;
}

.data-availability-list li:nth-child(3) label {
  background-color: #55bbff;
}

.data-availability-list li:last-child label {
  background-color: #ff5454;
}

/* data availability popup */

.product-available-wrapper {
  position: absolute;
  top: 12px;
  left: 12px;
}

.popup-heading {
  display: flex;
  align-items: center;
  background: white;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  justify-content: space-between;
}

.popup-body.show {
  min-height: auto;
  visibility: visible;
  padding: 10px 10px 10px 25px;
}

.popup-body.show li,
.popup-heading h1 {
  font-size: var(--main-txt-size-14);
  line-height: var(--main-line-height-14);
  color: var(--main-txt-dark-charcoal-color);
  cursor: pointer;
}

.popup-heading label img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.popup-heading .arrow-image {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.arrow-image.rotate-image {
  transform: rotate(180deg);
}

.popup-body {
  min-height: 0px;
  background: white;
  border-radius: 5px;
  margin-top: 2px;
  visibility: collapse;
}

.popup-body li {
  padding: 5px;
  list-style-type: decimal;
  word-break: break-word;
}

.map-overlay {
  background: linear-gradient(180deg,
      rgba(0, 0, 0, 65%) 12.21%,
      rgba(0, 0, 0, 65%) 100%);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--main-txt-white-color);
  z-index: 1;
  font-weight: var(--main-font-weight-500);
  border-radius: 8px;
}

/*  */
.ol-full-screen {
  display: none;
}