.common-btn-loader {
  margin-left: 10px;
  width: 25px;
  height: 25px;
}

.common-btn-loader .loader {
  animation: spin 2s linear infinite;
}

.loader {
  border: 3px solid #42444a;
  border-top: 3px solid #fff;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.table-loader {
  width: 25px;
  height: 25px;
}

.table-loader .loader {
  border: 3px solid var(--main-bg-green-color);
  border-top: 3px solid #e3f1ff;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: spin 2s linear infinite;
}

.request-loader {
  width: 17px;
  height: 16px;
  margin-right: 16px;
  margin-left: 4px;
}

.request-child-loader {
  width: 16px;
  height: 16px;
}

.request-child-loader .loader {
  border: 3px solid #1b75be;
  border-top: 3px solid #e3f1ff;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: spin 2s linear infinite;
}

.request-loader .loader {
  border: 3px solid #1b75be;
  border-top: 3px solid #e3f1ff;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(90deg);
  }

  100% {
    transform: rotate(360deg);
  }
}