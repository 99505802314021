.document-heading {
  font-weight: 700;
  font-size: var(--main-txt-size-16);
  line-height: var(--main-line-height-22);
  color: #42444A !important;
  margin-bottom: 16px;
}

.advantage-container p,
.advantage-list-item li,
.document-common-para {
  font-weight: var(--main-font-weight-500);
  font-size: var(--main-txt-size-14);
  line-height: var(--main-line-height-19);
  color: #42444A;
  text-align: justify;
}

.main-desc-section {
  display: flex;
  justify-content: center;
}

.intro1-section p {
  padding: 15px 0px;
}

.use_case_header {
  font-weight: var(--main-font-weight-600);
  font-size: var(--main-txt-size-16);
  line-height: var(--main-line-height-22);
  color: #333333;
  margin-bottom: 16px !important;
}

.document-intro h4 {
  font-weight: var(--main-font-weight-500);
  font-size: var(--main-txt-size-14);
  line-height: var(--main-line-height-17);
  color: #828282;
  text-transform: capitalize;
}

.margin-top {
  margin-top: 20px;
}

.document-intro h2 {
  font-weight: 700;
  font-size: var(--main-txt-size-24);
  line-height: 33px;
  color: #333333;
  text-transform: uppercase;
  padding: 16px 0px 6px;
}

.document-intro p {
  font-weight: var(--main-font-weight-500);
  font-size: var(--main-txt-size-16);
  line-height: var(--main-line-height-22);
  color: #42444A;
  text-align: justify;
}

.document-intro hr {
  margin-top: 5px;
}

.use_case_para,
.usecase-list-item li {
  font-weight: var(--main-font-weight-500);
  font-size: var(--main-txt-size-14);
  line-height: var(--main-line-height-19);
  color: #4F4F4F;
  text-align: inherit;
}

.advantage-list-item {
  font-size: var(--main-txt-size-14);
  padding-left: 20px;
  margin-top: 10px;
}

.advantage-list-item li {
  list-style-type: decimal;
}

.usecase-list-item li {
  list-style-type: disc;
}

.usecase-list-item {
  padding-left: 20px;
  margin-top: 10px;
}

.figures,
.formula1 {
  display: flex;
  justify-content: center;
  font-weight: var(--main-font-weight-600);
  font-size: var(--main-txt-size-14);
  line-height: var(--main-line-height-19);
  color: #42444A !important;
  padding: 24px 0px !important;
}

.desc-img {
  max-width: 100%;
  height: auto;
}

.document-image-wrapper {
  width: 70%;
  margin: 0 auto;
}

.document-image-wrapper img {
  width: 100%;
  height: 100%;
}

.figures {
  text-align: center;
  margin-bottom: 10px;
}

.legend-image-wrapper {
  text-align: center;
  margin-top: 20px;
}


.sidenav-header{
  color: var(--gray-1, #333);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 30px;
}
.side-nav-container li{
  color: var(--gray-2, #4F4F4F);
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 10px;
  list-style-type: disc;
}

.side-nav-container li::marker{
  text-align: center  !important;
  text-indent: 10px !important;
  text-align-last: center !important ;

}
.side-nav-container li:hover{
  color: var(--blue-1, #2F80ED);
}