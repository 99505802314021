.dashboard-wrapper {
  height: calc(100vh - 60px);
  background-color: var(--main-txt-white-color);
}

.data-request-filter {
  height: 117px;
  background-color: #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.run-request {
  font-weight: var(--main-font-weight-600);
  font-size: var(--main-txt-size-16);
  line-height: var(--main-line-height-22);
  border-radius: 8px;
  height: 38px;
}

.data-map-sections {
  height: calc(100vh - 177px);
}

.run-request-btn {
  flex-basis: 15%;
  margin-top: 5px;
}

.data-request-filter .filter-form-group {
  flex-basis: 25%;
  width: 25%;
  position: relative;
}

.crop.filter-form-group {
  flex: 0.65;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker {
  display: flex !important;
}

.react-datepicker-popper {
  z-index: 3 !important;
}

.react-datepicker__input-container input::placeholder {
  color: var(--main-txt--sat-lite-sure-grey);
  ;
}

.component-sections {
  height: 100%;
}

.rs-anim-fade.rs-anim-in {
  z-index: 99;
}

input[type='file'] {
  display: none;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #333333 !important;
}

.filter-form-group .btn-register {
  margin-top: 15px;
}

.product-filter-title {
  font-weight: var(--main-font-weight-600);
  font-size: var(--main-txt-size-15);
  line-height: var(--main-line-height-20);
  color: var(--main-txt-dark-charcoal-color);
  margin-bottom: 8px;
}

.custom-file-upload {
  border: 1px solid #e0e0e0 !important;
  padding: 5px 10px;
  cursor: pointer;
  background: var(--main-bg-white-color);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  height: 38px;
  align-items: center;
}

.custom-file-upload-text {
  font-size: var(--main-txt-size-14);
  line-height: var(--main-line-height-24);
  color: var(--main-txt-black-color) !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
}

.custom-file-upload-placeholder .custom-file-upload-text {
  color: var(--main-txt--sat-lite-sure-grey) !important;
}

.custom-file-upload:hover,
.custom-file-upload-placeholder:hover {
  border: 1px solid #3DB267 !important;
}

.custom-file-upload.error {
  border: 1px solid red;
}

.triangle-top {
  height: 12px;
  left: 17%;
  position: absolute;
  top: -12px;
  transform: translateX(-50%);
  z-index: 1;
}

.triangle-top::before {
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  background: #000;
  content: '';
  height: 12px;
  position: absolute;
  width: 25px;
}

.info-icon {
  cursor: pointer;
  margin-left: 5px;
  display: inline-block;
}

.info-icon:hover~.info-popup {
  display: block;
}

.aoi-format {
  font-weight: var(--main-font-weight-600);
  font-size: var(--main-txt-size-10);
  line-height: var(--main-line-height-12);
  color: var(--main-txt-grey-dim-color);
  margin-top: 3px;
}

.aoi.info-popup {
  left: 0;
}

.aoi.info-popup .triangle-top {
  left: 13%;
}

.info-popup {
  display: none;
  position: absolute;
  z-index: 2;
  background: rgba(0, 0, 0, 0.92);
  border-radius: 8px;
  padding: 10px;
  top: 32px;
  left: 22px;
  width: 210px;
}

.info-content {
  font-size: var(--main-txt-size-12);
  line-height: var(--main-line-height-14);
  color: var(--main-txt-white-color);
}

.info-icon img {
  width: 100%;
  height: 100%;
}

.rs-picker-toggle-wrapper {
  margin-bottom: 8px !important;
}

.rs-picker-default .rs-picker-toggle.rs-btn-md {
  padding-bottom: 8px !important;
  padding-top: 8px !important;
}

.data-map-wrapper {
  display: flex;
  height: calc(100vh - 177px);
}

.component-wrapper {
  flex-basis: 85%;
  height: calc(100vh - 177px);
  padding: 20px;
  padding-bottom: 40px;
  position: relative;
}

.data-map-container {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.data-res-met-sections,
.map-sections {
  flex-basis: 49.5%;
}

.compare-map .map-sections {
  flex-basis: 49.5%;
}

.expand-collapse-section {
  height: 100%;
  padding-right: 10px;
}

.request-wrapper {
  height: 50px;
  background: var(--main-bg-white-color);
  border-radius: 10px 10px 0px 0px;
  border: 2px solid #e0e0e0;
}

.request-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
}

.request-head-item {
  display: flex;
  align-items: center;
  flex-basis: 50%;
}

.request-head-item h3 {
  font-weight: var(--main-font-weight-600);
  font-size: var(--main-txt-size-12);
  line-height: var(--main-line-height-16);
  color: #4f4f4f;
  margin-bottom: 0px;
}

.request-head-item.second {
  justify-content: flex-end;
}

.request-head-item.second .key-select__control {
  padding-left: 10px;
}

.request-head-item.second .key-select__control:hover {
  background-color: #8080803b;
}

.request-head-item h1 {
  font-size: var(--main-txt-size-15);
  font-weight: var(--main-font-weight-600);
  color: var(--main-txt-dark-charcoal-color);
  line-height: var(--main-line-height-20);
  margin: 0px 10px;
}

.product-filter .react-select__control {
  margin-bottom: 0px;
  border: 1px solid #e0e0e0 !important;
  border-radius: 8px;
  height: 35px;
}

.product-filter .react-select__control--is-focused {
  box-shadow: none;
}

.product-filter .react-select__control:focus-within,
.product-filter .react-select__control:hover {
  caret-color: #3DB267;
  border: 1px solid #3DB267 !important;
}

.product-filter .react-select__indicator {
  color: var(--main-txt-black-color) !important;
}

.product-filter .react-select__placeholder {
  color: var(--main-txt--sat-lite-sure-grey) !important;
  font-size: var(--main-line-height-14);
}

.date-select .react-datepicker__input-container ::placeholder {
  color: var(--main-txt-black-color) !important;
  font-size: var(--main-txt-size-14);
}

.margin-bottom {
  margin-bottom: 25px;
}

.compare-button:disabled,
.run-request:disabled {
  background-color: #bdbdbd;
  color: var(--main-txt-grey-color);
  cursor: not-allowed;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
}

.latest-available {
  color: #3DB267;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.1px;
  margin: 3px 0px 9px 0px !important;
  height: 15px;
}

.latest-available.date-show {
  visibility: inherit;
}

.latest-available.date-hide {
  visibility: hidden;
}

.aoi-error {
  display: block;
  font-weight: var(--main-font-weight-500);
  font-size: var(--main-txt-size-10);
  line-height: var(--main-line-height-12);
  color: #eb5757;
  visibility: hidden;
}

.aoi-error.visible {
  visibility: visible;
}

.request-wrapper-body {
  height: 40%;
  border: 2px solid #e0e0e0;
  border-top: none;
  border-radius: 0px 0px 10px 10px;
  overflow-y: auto;
}

.request-wrapper.collapsed,
.request-wrapper-body.margin-bottom {
  margin-bottom: 10px;
}

.request-wrapper-body.collapse {
  visibility: collapse;
}

.request-wrapper.collapsed {
  border-radius: 10px;
}

.text-tooltip {
  display: block;
}

.text-tooltip .copy:hover {
  background: #8080803b;
}

.copy.copied {
  pointer-events: none;
  cursor: default;
}

.arrow-wrapper {
  width: 16px;
  height: 20px;
  cursor: pointer;
  transform: rotate(180deg);
  display: flex;
}

.arrow-wrapper img {
  width: 100%;
  height: 100%;
}

.arrow-wrapper.rotate {
  transform: rotate(0deg);
}

.body-data {
  white-space: break-spaces;
  word-break: break-all;
  padding: 10px;
  font-size: var(--main-txt-size-16);
  line-height: 28px;
  color: #4f4f4f;
  overflow-y: auto;
}

.request-head-item .key-select__single-value {
  font-size: var(--main-txt-size-12);
  font-weight: var(--main-font-weight-600);
  line-height: var(--main-line-height-16);
}

.aoi-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.sample-json {
  font-size: var(--main-txt-size-12);
  font-weight: var(--main-font-weight-600);
  line-height: var(--main-line-height-12);
  color: var(--main-txt-dark-blue-color) !important;
  cursor: pointer;
}

.copy {
  font-size: var(--main-txt-size-12);
  font-weight: var(--main-font-weight-600);
  line-height: var(--main-line-height-16);
  color: #0db02b;
  cursor: pointer;
  margin-left: 20px;
  border-radius: 8px;
  padding: 10px;
}

.copy-button,
.copied-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 51px;
  border-radius: 4px;
  cursor: pointer;
}

.copy-button {
  background-color: #2f80ed;
  padding: 4px 10px 7px 11px;
}

.copied-button {
  background-color: #3db267;
  padding: 4px 4px 7px 5px;
}

.copy-text {
  color: var(--main-bg-white-color);
  font-family: var(--main-font-family);
  font-size: var(--main-txt-size-12);
  font-weight: var(--main-font-weight-600);
  line-height: var(--main-line-height-16);
}

.expand-content-wrapper {
  width: 33px;
  height: 33px;
  display: flex;
  margin-right: 5px;
  margin-left: 20px;
  cursor: pointer;
  padding: 10px;
}

.expand-content-wrapper:hover {
  background: #8080803b;
  border-radius: 8px;
}

.expand-content-wrapper img {
  width: 100%;
  height: 100%;
}

/* Scroll bar thumb */
/* scrollbar */
::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(17, 99, 102, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(17, 99, 102, 0.5);
}

.merge-map {
  top: 0px;
  left: 50%;
  position: absolute;
  transform: translate(-50%, 0%);
  background: var(--main-bg-green-color);
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  width: 42px;
  height: 42px;
  text-align: center;
  border-radius: 100%;
  padding: 10px;
  display: flex;
  cursor: pointer;
  z-index: 1;
}

.merge-map.disable {
  background: #bdbdbd;
}

.react-datepicker__input-container input {
  width: 100%;
  font-size: var(--main-txt-size-14);
  line-height: var(--main-line-height-20);
  color: var(--main-txt-black-color) !important;
  border: 1px solid #e0e0e0;
  display: block;
  padding: 10px 12px;
  border-radius: 8px;
  height: 38px;
}

.react-datepicker__input-container input:focus-visible,
.react-datepicker__input-container input:hover {
  border: 1px solid #3DB267;
  outline: 1px solid #3DB267;
}

.react-datepicker__input-container::placeholder {
  color: var(--main-txt-black-color) !important;
}

.react-datepicker__close-icon::after {
  background-color: transparent;
  border: 2px solid #000000;
  height: 18px;
  width: 18px;
  padding: 0px;
  font-size: var(--main-txt-size-16);
  color: var(--main-txt-black-color);
  line-height: 0;
  border-radius: 5px;
}

.react-datepicker__close-icon {
  padding: 0 15px 0 0;
}

.merge-map img {
  width: 100%;
  height: 100%;
}

.req-compare-header {
  display: flex;
  justify-content: space-evenly;
  height: 45px;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
}

.req-compare-header label {
  font-weight: var(--main-font-weight-500);
}

.compare-button button {
  border: 2px solid #2f80ed;
  background: #ffff;
  border-radius: 8px;
  font-size: var(--main-txt-size-14);
  width: 80px;
  height: 30px;
  color: var(--main-txt-dark-blue-color);
}

.merge-content {
  margin: 16px 0px !important;
}

.merge-content p {
  font-size: var(--main-txt-size-14);
  line-height: var(--main-line-height-17);
  color: var(--main-txt-black-color);
}

.modal-btn .modal-save-btn,
.merge-btn .yes-btn {
  background-color: #0db02b;
  color: var(--main-txt-white-color);
  margin-right: 16px;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.modal-btn .discard-btn,
.merge-btn .skip-btn {
  border: 1px solid #108579;
  color: #108579;
}

.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1,
.react-datepicker__quarter-text--highlighted-custom-1,
.react-datepicker__year-text--highlighted-custom-1 {
  background-color: #ffc107;
  border-radius: 0.3rem;
  color: #fff;
}

.single-map-sections {
  width: 100%;
}

.merge-slpit {
  top: 0px;
  left: 50%;
  position: absolute;
  transform: translate(-50%, 0%);
  background: #0db02b;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  width: 42px;
  height: 42px;
  text-align: center;
  display: flex;
  border-radius: 100%;
  padding: 10px;
  cursor: pointer;
}

.key-select__control {
  margin-right: 10px;
  border: transparent !important;
  box-shadow: none !important;
  border-color: transparent;
  width: 110px;
}

.key-select__value-container,
.key-select__indicator {
  cursor: pointer !important;
}

.key-select__control--is-focused {
  padding: 0px;
}

.key-select__indicators {
  padding: 0px;
}

.key-select__indicators span {
  display: none !important;
}

.key-select__indicator,
.key-select__value-container {
  padding: 0px !important;
}

.key-select__option--is-selected {
  background-color: #c4c4c463;
}

.key-select__option {
  background-color: white !important;
  color: #000 !important;
  font-weight: var(--main-font-weight-500);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.key-select__option--is-selected {
  background-color: transparent !important;
  color: #2d9cdb !important;
}

.key-select__option--is-focused {
  background-color: #0db02b !important;
  color: var(--main-txt-white-color) !important;
}

.key-select__input {
  caret-color: transparent;
}

.key-select__placeholder {
  color: #4f4f4f !important;
  font-weight: var(--main-font-weight-600);
  font-size: var(--main-txt-size-12);
  line-height: var(--main-line-height-16);
  text-align: center;
}

.key-select__menu-list {
  max-height: 250px !important;
}

.daterange-picker {
  background: url('../../assets/images/calender.svg') no-repeat right;
  background-size: contain;
  background-origin: content-box;
  background-color: var(--main-txt-white-color);
}

.no-data {
  word-break: break-all;
  padding: 10px;
  font-size: var(--main-txt-size-16);
  line-height: 28px;
  color: #4f4f4f;
  overflow-y: auto;
}

.request-body {
  display: flex;
  flex-direction: column;
}

.request-head-item.second .copy-tooltip {
  top: 28px;
  right: inherit;
}

.request-head-item.second .copy-tooltip::before {
  bottom: inherit;
  right: inherit;
  top: -9px;
  left: 50%;
  rotate: 90deg;
}