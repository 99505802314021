.apiKeyContainer {
  padding: 80px;
}

.apiKeyHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.api-key-text h1 {
  font-weight: var(--main-font-weight-700);
  font-size: var(--main-txt-size-20);
  line-height: var(--main-line-height-27);
  color: var(--main-txt-black-color);
}

.api-key-text p {
  font-weight: var(--main-font-weight-600);
  font-size: var(--main-txt-size-14);
  line-height: var(--main-line-height-19);
  color: var(--main-txt-grey-color);
  margin-top: 3px;
}

.create-api-key {
  padding: 10px;
  background-color: var(--main-bg-green-color);
  border-radius: 8px;
  cursor: pointer;
}

.name-key {
  display: flex;
}

.name-key label {
  display: block;
  margin-right: 5px;
}

.name-key span {
  display: block;
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.create-api-key span {
  font-weight: var(--main-font-weight-600);
  font-size: var(--main-txt-size-14);
  line-height: var(--main-line-height-19);
  color: var(--main-txt-white-color);
}

.api-key-popup {
  text-transform: capitalize;
  padding: 15px 24px;
  font-weight: var(--main-font-weight-500);
  font-size: var(--main-txt-size-16);
  line-height: var(--main-line-height-19);
  color: var(--main-txt-black-color);
  border-bottom: 1px solid var(--main-border-lite-grey-color);
}

.text-ellipsis {
  width: 360px;
  vertical-align: text-top;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
}

.key-name {
  margin-bottom: 30px;
}

.key-name label {
  font-weight: var(--main-font-weight-500);
  font-size: var(--main-txt-size-14);
  line-height: var(--main-line-height-17);
  color: var(--main-txt-grey-color);
  margin-bottom: 8px;
  display: block;
}

.api-key-body {
  padding: 24px;
}

.key-success {
  padding: 15px;
  background-color: rgba(217, 235, 206, 0.5);
  border: 1px solid var(--main-border-green-color);
  border-radius: 8px;
  margin-bottom: 16px !important;
}

.key-success li:first-child {
  font-size: var(--main-txt-size-12);
  line-height: var(--main-line-height-14);
  ;
  color: var(--main-txt-grey-color);
  margin-bottom: 12px;
}

.key-name .common-input-feild .input-text:focus {
  border: 1px solid var(--main-border-green-color);
}

.key-success li:last-child {
  margin-top: 8px;
}

.key-success li {
  font-weight: var(--main-font-weight-500);
  font-size: var(--main-txt-size-14);
  line-height: var(--main-line-height-17);
  color: var(--main-txt-black-color);
}

.api-key-body .common-modal-btn {
  display: flex;
  justify-content: flex-end;
}

.color-btn.margin-right {
  margin-right: 20px;
}

.product-table {
  margin: 16px 0px;
  border-collapse: separate;
  border-radius: 8px;
  border: 1px solid var(--main-border-lite-grey-color);
  width: 100%;
  display: block;
}

.product-table thead {
  display: inline-block;
  width: 100%;
}

.product-table tbody {
  max-height: calc(100vh - 266px);
  display: inline-block;
  overflow-y: auto;
  width: 100%;
}

.product-row {
  width: 100%;
  display: inline-table;
}

.product-row th {
  display: inline-block;
  font-weight: var(--main-font-weight-600);
  font-size: var(--main-txt-size-16);
  line-height: var(--main-line-height-20);
  color: var(--main-txt-black-color);
  border-bottom: 1px solid #e0e0e0cd;
}

.width-17 {
  width: 17%;
}

.width-32 {
  width: 32%;
}

.product-body-row {
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-500);
  width: 100%;
  display: inline-table;
}

.product-1 {
  font-weight: var(--main-font-weight-600);
  color: var(--main-txt-black-color);
}

.key-value {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 17px 0px;
}

.key-value .text-tooltip {
  margin-left: 20px;
}

.key-value .text-tooltip .copy-img {
  margin-left: 15px;
}

.regenerate {
  color: var(--main-txt-dark-blue-color) !important;
  cursor: pointer;
  font-weight: var(--main-font-weight-600);
}

.product-body-row:not(.product-1) {
  color: var(--main-txt-grey-color);
}

th,
td {
  padding: 15px;
  text-align: center;
}

table {
  width: 100%;
}

.copy-img {
  width: 17px;
  height: 17px;
  display: inline-block;
  cursor: pointer;
}

.copy-img img {
  width: 100%;
  height: 100%;
}

tbody tr:not(:last-child) td {
  border-bottom: 1px solid #e0e0e0cd;
}

.switch {
  position: relative;
  display: inline-block;
  width: 46px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #2196f3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.create-api-key label {
  padding-right: 10px;
}

.create-api-key label img {
  width: 100%;
  height: 100%;
}