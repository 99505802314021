.profile-container {
  height: 100%;
  padding: 50px;
}

.profile-header h1 {
  font-weight: var(--main-font-weight-500);
  font-size: var(--main-txt-size-20);
  line-height: var(--main-line-height-24);
  color: var(--main-txt-dull-navy-color);
}

.profile-header hr {
  border: 1px solid #e0e0e0;
}

.profile-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.name-first-last {
  display: flex;
  justify-content: space-between;
}

.common-width {
  flex-basis: 45%;
}

.common-width-name {
  flex-basis: 48%
}

.profile-form .email-disabled,
.profile-form .profile-input {
  font-size: var(--main-txt-size-16);
  line-height: var(--main-line-height-19);
  background: var(--main-bg-white-color);
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  padding: 8px 16px;
  height: 36px;
}

.profile-form label {
  display: block;
  font-weight: var(--main-font-weight-500);
  font-size: var(--main-txt-size-16);
  line-height: var(--main-line-height-19);
  color: var(--main-txt-suva-grey-color);
  margin-bottom: 8px;
}

.profile-input {
  width: 100%;
  color: var(--main-txt-dull-navy-color);
}

.profile-form .email-disabled {
  color: var(--main-border-grey-color);
  background-color: #e0e0e0;
}

.profile-form .email-disabled {
  display: block;
}

/* profile-name */
.profile-description {
  position: relative;
  padding: 61px 59px;
  background-color: #f2f2f2;
  height: 200px;
  width: 200px;
  border-radius: 100%;
  margin: 0 auto;
}

.profile-name label {
  font-weight: var(--main-font-weight-600);
  font-size: 65px;
  line-height: 78px;
  color: #515D53;
}

.edit-profile {
  cursor: pointer;
  position: absolute;
  right: 18px;
  bottom: 20px;
}

.profile-edit {
  flex-basis: 60%;
  padding-right: 52px;
  height: calc(100vh - 218px);
}

.profile-form {
  margin-bottom: 16px;
}

.profile-name {
  flex-basis: 40%;
}

.border-divider {
  border-left: 1px solid #E0E0E0;
  height: calc(100vh - 320px);
}

.save-btn {
  display: block;
  margin: 0 auto;
  margin-top: 20px;
  padding: 8px 36px;
  font-weight: var(--main-font-weight-500);
  font-size: var(--main-txt-size-16);
  line-height: var(--main-line-height-19);
  color: var(--main-txt-white-color);
  background: var(--main-bg-green-color);
  border-radius: 4px;
}