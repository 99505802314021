.close-button {
  float: right;
  color: var(--main-txt-black-color);
  background-color: white !important;
  padding: 5px 15px;
  font-size: xx-large;
}

.close-button img {
  width: 25px;
  height: 30px;
}

.close-button span {
  display: flex;
  width: 25px;
  height: 25px;
}

.text-area1 {
  width: 500px;
  padding: 8px 16px;
  resize: none;
  font-size: var(--main-txt-size-14);
  border-radius: 5px;
  border: 1px solid #888888;
}

.close-button span:hover {
  background-color: #EEEEEE !important;
}

.requestDem-container p {
  font-weight: var(--main-font-weight-500);
  font-size: var(--main-txt-size-16);
  line-height: var(--main-line-height-17);
  padding: 20px 0px 20px 0px;
}

.requestDem-container {
  text-align: left;
  height: 100%;
  padding: 20px 40px 20px 40px;
}

.requestDem-container h1 {
  font-weight: var(--main-font-weight-500);
  font-size: 30px;
  line-height: 29px;
}

.country-phone-company {
  display: flex;
  justify-content: space-between;
}

.country-phone-company {
  display: flex;
  justify-content: space-between;
}

.requestDem-list {
  padding: 30px 30px 0px 0px;
}

.contact-name-first-last {
  display: flex;
  justify-content: space-between;
}

.common-width {
  flex-basis: 32%;
}

.requestDem-form .email-disabled,
.requestDem-form input {
  font-size: var(--main-txt-size-14);
  width: 100%;
  background: var(--main-bg-white-color);
  border: 1px solid #888888;
  border-radius: 4px;
  padding: 8px 16px;
  height: 43px;
}

.requestDem-form2 input {
  display: flex;
  font-size: var(--main-txt-size-14);
  width: 500px;
  background: var(--main-bg-white-color);
  border: 1px solid #888888;
  border-radius: 4px;
  padding: 8px 16px;
  height: 143px;
}

.requestDem-form2 {
  padding-right: 20px;
  margin-bottom: 0px
}

.requestDem-form {
  margin-bottom: 35px;
}

.requestDem-name {
  flex-basis: 40%;
}

.width-66 {
  width: 66%;
}

.support-select .react-select__placeholder {
  color: #898989 !important;
}

.support-select .margin-bottom {
  margin-bottom: 35px;
}

.support-select .react-select__indicator {
  color: #1C1B1F !important;
}

.support-select .react-select__control {
  height: inherit;
}

.form-container2 {
  display: flex;
  align-items: flex-end;
  padding-right: 34%;
}

.request-btn {
  display: flex;
  height: 43px;
  margin-left: 95px;
  margin-bottom: 7px;
  padding: 12px 16px 12px 16px;
  float: right;
  font-weight: var(--main-font-weight-500);
  font-size: var(--main-txt-size-18);
  line-height: 23px;
  color: var(--main-txt-white-color);
  background: var(--main-bg-green-color);
  border-radius: 5px;

}

.request-btn label {
  padding-left: 15px;
  color: var(--main-txt-white-color);
}

.react-select .react-select__control {
  font-size: var(--main-txt-size-14);
  width: 100%;
  background: var(--main-bg-white-color);
  border: 1px solid #888888 !important;
  border-radius: 4px;
  padding: 5px 10px;
  height: 43px;
}

.react-select .react-select__placeholder {
  color: #888888 !important;
  margin-left: 0px;
}

.request-btn label {

  padding-bottom: 20px;
}

.react-select .react-select__input-container {
  grid-area: 1/1/3/3;
  margin: 0px;
  padding-top: 0px;
}

.react-select__indicator-separator {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  width: 0px !important;
}

.react-select .react-select__indicator {
  color: #888888 !important;
  padding-bottom: 30px;
}

.react-select .react-select__multi-value {
  margin-bottom: 30px;
}