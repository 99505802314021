.modal {
  position: fixed;
  top: 30%;
}

.pointer-event {
  pointer-events: none;
}

/* .hover-container .download-button {
  visibility: hidden;
}

.hover-container:hover .download-button {
  visibility: visible;
} */

/* .sub-request-name-popup:hover+ .download-modal-btn{
  display: block;
} */

/* .sub-request-name:hover{
  color: blue !important;
} */

.hover-container {
  display: none;
}

.sub-request:hover > .hover-container {
  display: block !important;
}

.child_no_data {
  color: rgb(79, 79, 79);
  font-weight: 500;
  padding-top: 7px;
  margin-left: 45px;
}

.api-key-popup {
  font-weight: 600;
  font-size: 17px;
  color: #42444a;
}

.input_field {
  padding: 20px 20px 20px 8px;
  color: #42444a;
}

.input_field input[type='radio'] {
  appearance: none;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid #ccc;
  margin-right: 8px;
  position: relative;
}

.input_field input[type='radio']:checked {
  background-color: #42444a;
  border-color: #42444a;
}

.input_field input {
  color: #42444a;
}

.input_field label {
  padding-left: 10px;
  font-weight: 600;
  font-size: 14px;
  color: #42444a;
}

.modal_download {
  padding: 8px;
  background-color: #3db267;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 15px;
}

.modal_cancel {
  padding: 7px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #3db267;
  color: #3db267;
  cursor: pointer;
}

.download-modal-btn button {
  font-weight: var(--main-font-weight-500);
  font-size: var(--main-txt-size-14);
  line-height: var(--main-line-height-24);
  border-radius: 8px;
  width: 90px;
}

.download-modal-btn {
  display: flex;
  justify-content: flex-end;
}

.sub-request-name-popup {
  font-weight: 500;
  font-size: 12px;
  padding: 10px;
  color: #3db267 !important;
}

.input_field input[type='checkbox'] {
  position: absolute;
  width: 40px;
  height: 10px;
  background: #555;
  margin: 20px 80px;
  border-radius: 3px;
}

.req-title-content::after {
  background-color: #3db267;
}
