.map-compare {
  height: 100%;
  width: 100%;
}

.map-compare .ol-viewport {
  border-radius: 8px;
}

.merge-croparea-aoi {
  position: absolute;
  top: 10px;
  right: 50px;
  z-index: 2;
}

.product-legend-img {
  border-radius: 4px;
  padding-top: 10px;
}

.product-legend-img img {
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 8px;
  background-color: #f6f6f6;
}

.single-merge-legend .map-descriptions.merge-map-title {
  flex-basis: 100%;
  justify-content: space-between;
}

.merge-map-title .product-legend-img {
  flex-basis: 45%;
}

.merge-map-component {
  flex-basis: 87%;
  position: relative;
}

.merge-container {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #f3f3f3;
}
