.analyticsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 40px 18px;
}

.analyticsHeader h1 {
  font-weight: var(--main-font-weight-500);
  font-size: var(--main-txt-size-20);
  line-height: var(--main-line-height-24);
  color: var(--main-txt-black-color);
}

.analyticsHeader a {
  font-weight: var(--main-font-weight-500);
  font-size: var(--main-txt-size-14);
  line-height: var(--main-line-height-17);
  text-decoration-line: underline !important;
  color: var(--main-txt-dark-blue-color) !important;
}

.product-apiKey {
  display: flex;
  padding: 20px 40px;
  background-color: #f9f9f9;
  margin-bottom: 18px;
}

.select-radio li:not(:last-child) {
  margin-bottom: 18px;
}

.select-radio li span {
  font-size: var(--main-txt-size-12);
  line-height: var(--main-line-height-14);
  color: var(--main-txt-dark-charcoal-color);
  margin-left: 10px;
}

.vr-line {
  border: 1px solid #bdbdbd;
  margin: 0px 40px 0px 0px;
}

.select-radio {
  flex-basis: 15%;
}

.product-timerange {
  justify-content: space-between;
  flex-basis: 40%;
  display: flex;
}

.select-list {
  flex-basis: 45%;
}

.select-api-list {
  display: flex;
  justify-content: space-between;
  padding-left: 0px;
}

.api-card {
  cursor: pointer;
  flex-basis: 33%;
  padding: 12px 0px 12px 40px;
  border-top: 2px solid transparent;
}

.api-card.active {
  background-color: #f2fdfc;
  border-top: 2px solid #0db02b;
}

.api-card h2 {
  font-weight: var(--main-font-weight-500);
  font-size: var(--main-txt-size-14);
  line-height: var(--main-line-height-17);
  color: var(--main-txt-dark-charcoal-color);
}

.api-card label {
  display: block;
  font-size: var(--main-txt-size-10);
  line-height: var(--main-line-height-12);
  color: var(--main-txt-dark-charcoal-color);
  padding: 8px 0px 4px;
  cursor: pointer;
}

.api-card span {
  display: block;
  font-size: var(--main-txt-size-20);
  line-height: var(--main-line-height-24);
  color: var(--main-txt-dark-charcoal-color);
}

.api-analytics-chart {
  width: 100%;
  height: calc(100vh - 400px);
  padding: 40px 20px;
}

.api-line {
  width: 100% !important;
  height: 100% !important;
}