@font-face {
  font-family: 'Manrope';
  src: url('../font/Manrope-Regular.ttf');
}

/* variables */
:root {
  /* Text Color */
  --main-txt-green-color: #0db02b;
  --main-txt-white-color: #ffffff;
  --main-txt-lite-grey-color: #e0e0e0;
  --main-txt-black-color: #000000;
  --main-txt-dark-charcoal-color: #333333;
  --main-txt-dark-shade-gray-color: #020202;
  --main-txt-grey-color: #4f4f4f;
  --main-txt-mid-grey-color: #878787;
  --main-txt-zambezi-color: #606060;
  --main-txt-grey-dim-color: #707070;
  --main-txt-suva-grey-color: #8F8F8F;
  --main-txt-dull-navy-color: #262261;
  --main-txt-approx-steel-grey-color: #42444a;
  --main-txt-dark-blue-color: #2f80ed;
  --main-txt--sat-lite-sure-grey: #696B72;
  --main-txt--sat-sure-grey-grey-3: #95969D;
  --main-txt--sparta-sparta-75: #3DB267;
  /* Bg Color */
  --main-bg-lite-grey-color: #eeeeee;
  --main-bg-white-color: #ffffff;
  --main-bg-green-color: #0db02b;

  /* Border Color */
  --main-border-green-color: #0db02b;
  --main-border-lite-grey-color: #e0e0e0;
  --main-border-grey-color: #828282;

  /* Font Size */
  --main-txt-size-10: 10px;
  --main-txt-size-12: 12px;
  --main-txt-size-13: 13px;
  --main-txt-size-14: 14px;
  --main-txt-size-15: 15px;
  --main-txt-size-16: 16px;
  --main-txt-size-18: 18px;
  --main-txt-size-20: 20px;
  --main-txt-size-22: 22px;
  --main-txt-size-24: 24px;

  /*Font Family */
  --main-font-family: 'Manrope';
  --main-font-weight-500: 500;
  --main-font-weight-600: 600;
  --main-font-weight-700: 700;
  --main-font-weight-800: 800;

  /* Line Height */
  --main-line-height-12: 12px;
  --main-line-height-14: 14px;
  --main-line-height-16: 16px;
  --main-line-height-17: 17px;
  --main-line-height-18: 18px;
  --main-line-height-19: 19px;
  --main-line-height-20: 20px;
  --main-line-height-22: 22px;
  --main-line-height-24: 24px;
  --main-line-height-27: 27px;
  --main-line-height-33: 33px;
}

* {
  font-family: 'Manrope' !important;
  margin: 0;
}

h1,
h2,
h3,
h4,
ul,
p {
  padding: 0px;
  margin: 0px;
  margin-bottom: 0px !important;
}

li {
  list-style-type: none;
}

Link,
a {
  text-decoration: none !important;
  color: inherit !important;
}

body {
  box-sizing: border-box;
}

button {
  border-width: 0px;
}

.common-modal-container {
  padding: 25px;
  background: var(--main-bg-white-color);
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 8px;
}

.modal-btn button {
  font-size: var(--main-txt-size-16);
  line-height: var(--main-line-height-19);
  border-width: 0px;
  width: 76px;
  height: 30px;
  border-radius: 8px;
  background-color: transparent;
}

/* Common Modal Button */
.common-modal-btn button {
  font-weight: var(--main-font-weight-500);
  font-size: var(--main-txt-size-14);
  line-height: var(--main-line-height-24);
  padding: 5px 15px;
  border-radius: 8px;
  width: 78px;
}

.common-modal-btn .color-btn {
  color: var(--main-txt-white-color);
  background-color: var(--main-bg-green-color);
  border: 1px solid var(--main-border-green-color);
}

.common-modal-btn .without-color-btn {
  color: var(--main-txt-green-color);
  background-color: transparent;
  border: 1px solid var(--main-border-green-color);
}

/* cursor-pointer */
.cursor-pointer,
.cursor-pointer label {
  cursor: pointer;
}

/* Input Feild */
.common-input-feild .input-text {
  display: block;
  width: 100%;
  font-size: var(--main-txt-size-12);
  line-height: var(--main-line-height-14);
  padding: 9px 16px;
  color: var(--main-txt-black-color);
  border: 1px solid var(--main-border-lite-grey-color);
  border-radius: 4px;
}

input:focus-visible {
  outline: 1px solid var(--main-border-lite-grey-color);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* copy-tooltip */
.text-tooltip {
  display: inline;
  position: relative;
}

.copy-tooltip {
  position: absolute;
  top: -10px;
  right: -65px;
  border: 1px solid #0db02b;
  border-radius: 8px;
  color: var(--main-txt-approx-steel-grey-color);
  text-align: center;
  font-size: var(--main-txt-size-12);
  padding: 5px 0px;
  background-color: #fff;
  font-weight: var(--main-font-weight-600);
  width: 60px;
}

.copy-tooltip::before {
  clip-path: polygon(100% 0, 0 50%, 100% 100%);
  background: var(--main-bg-green-color);
  content: '';
  height: 10px;
  position: absolute;
  width: 7px;
  bottom: 3px;
  right: 59px;
}

pre {
  word-break: break-all;
  white-space: break-spaces;
}

hr {
  color: var(--main-txt-lite-grey-color);
  opacity: inherit;
}

/* powerde by logo */
.powered-by {
  flex-basis: 10%;
}

.powered-by-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;

}

.powered-by-wrapper label {
  color: #283988;
  font-size: var(--main-txt-size-12);
  line-height: var(--main-line-height-14);
  margin-bottom: 10px;
}

button:disabled {
  background-color: #bdbdbd;
  color: var(--main-txt-grey-color);
  cursor: not-allowed;
}

/* custom react select options */
.custom-options-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}