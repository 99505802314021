.document-agri-intro h4 {
    font-weight: var(--main-font-weight-500);
    font-size: var(--main-txt-size-14);
    line-height: var(--main-line-height-17);
    color: #828282;
    text-transform: capitalize;
  }

.document-agri-intro h2 {
    font-weight: 600;
    font-size: var(--main-txt-size-20);
    color: #42444A;
    text-transform: uppercase;
    padding: 16px 0px 0px;
  }

  .document-agri-common-para {
    font-size: 15px;
    color: grey;
    font-weight: 500;
    text-transform: uppercase;
  }

.agri-head {
    font-size: 16px;
    font-weight: 600;
}

.agri-para{
    font-size: 14px;
    margin-top: 10px;
}

.margin-40{
    margin-top: 40px;
}

.read-more{
    color: #2D9CDB;
    font-size: 14px;
    text-decoration: underline;
}

.custom-paragraph{
    white-space: pre-line;
}

.des_points{
    display: list-item;
}

.bullet-list li{
    list-style: disc;
    padding-top: 10px;
    margin-left: 10px;
}