.header-wrapper {
  background: #42444a;
  box-shadow: 0px 1px 10px rgba(92, 92, 92, 0.15);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  height: 60px;
}

.logo-wrapper,
.icons-list,
.header {
  flex-basis: 33.3%;
}

.logo-sections {
  width: 86px;
  height: 33px;
}

.logo-wrapper img {
  width: 100%;
  height: 100%;
}

.header {
  display: flex;
  justify-content: center;
}

.header h1 {
  color: var(--main-txt-white-color);
  font-weight: var(--main-font-weight-700);
  line-height: var(--main-line-height-33);
  font-size: var(--main-txt-size-24);
}

.icons-list {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.icons-wrapper {
  cursor: pointer;
  display: flex;
}

.icons-wrapper:hover {
  background-color: rgba(142, 142, 142, 0.45);
  border-radius: 50%;
}

.icons-wrapper.notifications {
  padding: 13px 15px;
}

.icons-wrapper.support {
  padding: 15px 18px;
}

.icons-wrapper.documentation {
  padding: 14px
}

.icons-wrapper.profile {
  padding: 14px 15px;
}

.c-tool-tip {
  display: none;
  position: absolute;
  bottom: -35px;
  padding: 5px 8px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}

.notifications~.c-tool-tip {
  left: -20px;
}

.documentation~.c-tool-tip {
  left: -20px;
}

.icons-wrapper:hover~.c-tool-tip {
  display: flex;
}

.c-tool-tip p {
  font-size: var(--main-txt-size-12);
  line-height: var(--main-line-height-16);
  color: var(--main-txt-white-color);
}

.list-item {
  position: relative;
}

.icons-list .list-item:not(:last-child) {
  margin-right: 7px;
}

.dropdown-menu {
  min-width: 221px;
  padding: 10px 15px;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.dropdown-menu-item:first-child label {
  display: block;
  color: var(--main-txt-mid-grey-color);
  font-size: var(--main-txt-size-14);
  font-weight: var(--main-font-weight-600);
  line-height: var(--main-line-height-19);
  margin-bottom: 5px;
}

.dropdown-menu-item:first-child span {
  display: block;
  font-size: var(--main-txt-size-12);
  color: var(--main-txt-mid-grey-color);
  font-weight: var(--main-font-weight-600);
  line-height: var(--main-line-height-16);
}

.dropdown-menu-item:not(:first-child) span {
  font-size: var(--main-txt-size-12);
  color: var(--main-txt-zambezi-color);
  font-weight: var(--main-font-weight-600);
  line-height: var(--main-line-height-16);
}

.dropdown-menu .dropdown-menu-item:not(:first-child) {
  margin: 16px 0px;
  cursor: pointer;
}

.dropdown-menu-item img {
  width: 100%;
  height: 100%;
}

.dropdown-menu-item:not(:first-child) label {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  cursor: pointer;
}

.icons-wrapper.dropdown-toggle.show {
  padding: 15px;
  border-radius: 50%;
  background: #ffffffbf;
}

.dropdown-toggle::after {
  display: none;
}

.list-item.document-show {
  border-radius: 50%;
  background: var(--main-bg-white-color);
}

.toolitip-triangle {
  height: 12px;
  left: 80%;
  position: absolute;
  top: -8px;
  transform: translateX(-50%);
  z-index: 1;
}

.tooltip-popup {
  display: none;
  position: absolute;
  z-index: 1;
  background: rgba(0, 0, 0, 0.92);
  border-radius: 8px;
  padding: 20px;
  top: 60px;
  width: 155px;
  right: 0px;
  animation: show 0s both;
  animation-delay: 2s;
}

@keyframes show {
  from {
    visibility: hidden;
  }

  to {
    visibility: visible;
  }
}

.toolitip-triangle::before {
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  background: #000;
  content: '';
  height: 8px;
  position: absolute;
  width: 12px;
}